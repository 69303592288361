import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectSignerChain } from '../../../../store/web3/signer'
import { web3Service } from '../../../../store/web3/web3.service'
import { ApproveButton } from './ApproveButton'
import { ConnectingWalletPendingButton } from './ConnectingWalletPending'
import { ConnectWalletButton } from './ConnectWalletButton'
import { DisabledButton } from './DisabledButton'
import { UnbindState, useUnbindState } from './hooks'
import { InsufficientFundsButton } from './InsufficientFundsButton'
import { LoadingButton } from './LoadingButton'
import { PendingConfirmationButton } from './PendingConfirmationButton'
import { TooManyNftsButton } from './TooManyNftsButton'
import { UnbindButton, UnbindButtonProps } from './UnbindButton'
import { WrongChainButton } from './WrongChainButton'

export interface Props extends UnbindButtonProps {
  azukiBurnAmountPerNft: string
}

export const UnbindMultiButton: React.FC<Props> = ({
  txLoadingState,
  nftId,
  nftsToUnbind,
  l2NftsOwned,
  azukiBurnAmountPerNft,
}) => {
  const { t } = useTranslation()
  const expectedChain = web3Service.getRequiredSignerChain()
  const chain = useSelector(selectSignerChain)

  const unbindState = useUnbindState({
    azukiBurnAmountPerNft,
    l2NftsOwned,
    nftsToUnbind,
  })
  switch (unbindState) {
    case UnbindState.SHOW_DISABLED:
      return (
        <DisabledButton
          disabledText={t('Unbinding is not currently available')}
        />
      )
    case UnbindState.SHOW_BINDING_FAILED:
      return <DisabledButton disabledText={t('This NFT cannot be unbound')} />
    case UnbindState.SHOW_PENDING_CONFIRMATION:
      return <PendingConfirmationButton />
    case UnbindState.SHOW_CONNECTING_WALLET_PENDING:
      return <ConnectingWalletPendingButton />
    case UnbindState.SHOW_CONNECT_WALLET:
      return <ConnectWalletButton />
    case UnbindState.SHOW_WRONG_CHAIN:
      return (
        <WrongChainButton
          currentChainName={chain?.name}
          expectedChainName={expectedChain.name}
        />
      )
    case UnbindState.SHOW_UNBIND:
      return (
        <UnbindButton
          l2NftsOwned={l2NftsOwned}
          nftsToUnbind={nftsToUnbind}
          nftId={nftId}
          txLoadingState={txLoadingState}
        />
      )
    case UnbindState.SHOW_INSUFFICIENT_AZUKI_FUNDS:
      return <InsufficientFundsButton text={'insufficientAzuki'} />
    case UnbindState.SHOW_INSUFFICIENT_MOMIJI_FUNDS:
      return <InsufficientFundsButton text={'insufficientNfts'} />
    case UnbindState.SHOW_APPROVE_AZUKI:
      return <ApproveButton txLoadingState={txLoadingState} isMomiji={false} />
    case UnbindState.SHOW_APPROVE_MOMIJI:
      return <ApproveButton txLoadingState={txLoadingState} isMomiji={true} />
    case UnbindState.SHOW_LOADING:
      return <LoadingButton />
    case UnbindState.SHOW_TOO_MANY_NFTS:
      return <TooManyNftsButton />
  }
}
