import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StyledUnbindButton } from './StyledUnbindButton'

export const PendingConfirmationButton: React.FC = () => {
  const { t } = useTranslation()
  return <PurpleButton disabled>{t('Pending Confirmation')}</PurpleButton>
}

const PurpleButton = styled(StyledUnbindButton)`
  color: white;
  font-weight: 600;

  background: #c173ff;
  border: 3px solid #c173ff;
  box-sizing: border-box;
  box-shadow: 0px 3px 14px #c173ff;
`
