import React from 'react'
import styled from 'styled-components'
import { StyledUnbindButton } from './StyledUnbindButton'

interface Props {
  disabledText: string
}
export const DisabledButton: React.FC<Props> = ({ disabledText }) => {
  return <RedOutLineButton disabled>{disabledText}</RedOutLineButton>
}

const RedOutLineButton = styled(StyledUnbindButton)`
  border: 3px solid #ff0000;
  color: white;
  background: #28282f;
`
