import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HelperSection } from './HelperSection'
import { StyledP } from './StyledP'
import { StyledUnbindButton } from './StyledUnbindButton'

interface WrongChainProps {
  currentChainName?: string
  expectedChainName: string
}

export const WrongChainButton: React.FC<WrongChainProps> = ({
  currentChainName,
  expectedChainName,
}) => {
  const { t } = useTranslation()
  return (
    <HelperSection>
      <RedFilledButton disabled>{t('wrongNetwork')}</RedFilledButton>
      <StyledP>
        {t('wrongNetwork1')} {currentChainName ?? 'N/A'} {t('wrongNetwork2')}
      </StyledP>
      <StyledP>{t('changeNetwork')}</StyledP>
    </HelperSection>
  )
}

const RedFilledButton = styled(StyledUnbindButton)`
  color: white;

  background: #ff0000;
  border: 3px solid #ff0000;
  box-sizing: border-box;
  box-shadow: 0px 3px 14px #ff0000;
  font-weight: 600;
  position: relative;
`
