import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HelperSection } from './HelperSection'
import { StyledP } from './StyledP'
import { StyledUnbindButton } from './StyledUnbindButton'

export const TooManyNftsButton: React.FC = () => {
  const { t } = useTranslation()

  return (
    <HelperSection>
      <RedButton>{t('max10NftsToUnbind')}</RedButton>
      <StyledP>{t('max10NftsToUnbindDesc')}</StyledP>
    </HelperSection>
  )
}

const RedButton = styled(StyledUnbindButton)`
  border: 3px solid #ff0000;
  color: white;
  background: #28282f;
  font-weight: 400;
`
