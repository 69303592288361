import { BigNumber, BigNumberish } from 'ethers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from '../../../components'
import { selectGasPending } from '../../../store/web3/unbind.side-effects'
import { useNftLoadingState } from '../CollectionDisplay/hooks'
import { bn, fEth, fEthUsd } from './utils'

export interface CostBreakdownProps {
  unbindValuePerNft: string
  azukiBurnAmountPerNft: string
  nftsToUnbind: number
  ethUsdPrice: number
  azukiUsdPrice: number
  maticUsdPrice: number
  gasCost: BigNumber
}

export const CostBreakdown: React.FC<CostBreakdownProps> = ({
  unbindValuePerNft,
  nftsToUnbind,
  ethUsdPrice,
  azukiUsdPrice,
  maticUsdPrice,
  gasCost,
  azukiBurnAmountPerNft,
}) => {
  const { t } = useTranslation()
  const loading = useSelector(selectGasPending)
  const { statsLoaded } = useNftLoadingState()
  const spinner = loading && <Spinner size={1} />
  const totalAzukiBurnAmount = bn(azukiBurnAmountPerNft).mul(nftsToUnbind)
  const loadableValue = (value: string | number) => {
    return statsLoaded ? value : <Spinner size={1} />
  }
  const displayPrecision = 6

  const unbindFee = bn(unbindValuePerNft).mul(15).div(100)
  const netUnbindValue = bn(unbindValuePerNft).mul(85).div(100)
  const totalUnbindFee = unbindFee.mul(nftsToUnbind)
  const totalUnbindValue = netUnbindValue.mul(nftsToUnbind)

  const lEth = (v: BigNumberish) => loadableValue(fEth(v, displayPrecision))
  const lUsd = (x: BigNumberish, y: number) => loadableValue(fEthUsd(x, y))
  return (
    <Table>
      <thead>
        <tr>
          <th>{t('Item')} </th>
          <th>{t('Eth Price')} </th>
          <th>{t('USD Price')} </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td> {t('Gas Price')} </td>
          <RightAligned>≈ {spinner || lEth(gasCost)} MATIC</RightAligned>
          <RightAligned>
            ≈ ${spinner || lUsd(gasCost, maticUsdPrice)} USD
          </RightAligned>
        </tr>

        <tr>
          <td>
            {t('azukiBurnAmount')} (x{nftsToUnbind})
          </td>
          <RightAligned>{lEth(totalAzukiBurnAmount)} AZUKI</RightAligned>
          <RightAligned>
            ${lUsd(totalAzukiBurnAmount, azukiUsdPrice)} USD
          </RightAligned>
        </tr>
        <tr>
          <td>
            {t('unbindFee')} (x{nftsToUnbind})
          </td>
          <RightAligned>{lEth(totalUnbindFee)} ETH</RightAligned>
          <RightAligned>${lUsd(totalUnbindFee, ethUsdPrice)} USD</RightAligned>
        </tr>
        <tr>
          <td>
            {t('netUnbindValue')} (x{nftsToUnbind})
          </td>
          <RightAligned>{lEth(totalUnbindValue)} ETH</RightAligned>
          <RightAligned>
            ${lUsd(totalUnbindValue, ethUsdPrice)} USD
          </RightAligned>
        </tr>
      </tbody>
    </Table>
  )
}

const RightAligned = styled.td`
  text-align: right;
`

const Table = styled.table`
  width: 100%;
  color: white;
  border-collapse: separate;
  border-spacing: 0 1rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 18px;
  border: 2px solid #00ffc2;
  font-size: 1.0257rem;

  & thead {
    visibility: collapse;
  }

  & tr {
    border: 1px solid
      ${(props) => (props.color ? props.color : 'rgba(255, 255, 255, 0.459);')};
    padding: 0.5rem;
  }

  & td {
    padding: 0.5rem;
  }
`
