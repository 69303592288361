import React from 'react'
import styled from 'styled-components'
import { Spinner } from '../../../../components'
import { StyledUnbindButton } from './StyledUnbindButton'

export const LoadingButton: React.FC = () => {
  return (
    <LoadingButtonStyled disabled>
      <Spinner size={2} />{' '}
    </LoadingButtonStyled>
  )
}

const LoadingButtonStyled = styled(StyledUnbindButton)`
  border: 3px solid #000000;
  color: white;
  font-weight: 600;
`
