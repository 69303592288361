import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import {
  TransactionLoading,
  TransactionType,
  unbindGasSlice,
  unbindTxSlice,
} from '../../../../store/web3/unbind.side-effects'
import { StyledUnbindButton } from './StyledUnbindButton'

interface Props {
  isMomiji: boolean
  txLoadingState: TransactionLoading
}

export const ApproveButton: React.FC<Props> = ({
  isMomiji,
  txLoadingState,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      unbindGasSlice.actions.GAS_ESTIMATE_REQUESTED({
        txType: isMomiji
          ? TransactionType.APPROVE_MOMIJI
          : TransactionType.APPROVE_AZUKI,
      }),
    )
  }, [dispatch, isMomiji])

  return (
    <StyledUnbindButton
      disabled={
        txLoadingState === TransactionLoading.PENDING ||
        txLoadingState === TransactionLoading.ERROR ||
        txLoadingState === TransactionLoading.BROADCASTED
      }
      onClick={() => {
        dispatch(
          unbindTxSlice.actions.TX_REQUESTED({
            txType: isMomiji
              ? TransactionType.APPROVE_MOMIJI
              : TransactionType.APPROVE_AZUKI,
          }),
        )
      }}
    >
      {t(`approve${isMomiji ? 'Momiji' : 'Azuki'}`)}
    </StyledUnbindButton>
  )
}
