import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import MetaMask from '../../../../images/metamask.svg'
import { useAppDispatch } from '../../../../store'
import { signerSlice, SignerType } from '../../../../store/web3/signer'
import { StyledUnbindButton } from './StyledUnbindButton'

export const ConnectWalletButton: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  return (
    <YellowFilledButton
      onClick={() =>
        dispatch(
          signerSlice.actions.signerSelected({
            signerType: SignerType.METAMASK,
          }),
        )
      }
    >
      {t('connectWallet')} <MetaMaskIcon src={MetaMask} alt="metamask icon" />
    </YellowFilledButton>
  )
}

const MetaMaskIcon = styled.img`
  width: 2rem;
  margin-left: 1rem;
`

const YellowFilledButton = styled(StyledUnbindButton)`
  background: #fff201;
  border: 3px solid #fff201;
  box-sizing: border-box;
  box-shadow: 0px 3px 14px #fff201;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`
