import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StyledUnbindButton } from './StyledUnbindButton'

interface Props {
  text: string
}
export const InsufficientFundsButton: React.FC<Props> = ({ text }) => {
  const { t } = useTranslation()
  return <RedOutLineButton disabled>{t(text)}</RedOutLineButton>
}

const RedOutLineButton = styled(StyledUnbindButton)`
  border: 3px solid #ff0000;
  color: white;
  background: #28282f;
  font-weight: 400;
`
