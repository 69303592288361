import styled from 'styled-components'

interface UnbindProps {
  disabled?: boolean
}

export const StyledUnbindButton = styled.button<UnbindProps>`
  color: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  font-weight: 600;

  font-family: 'Open Sans';
  font-size: 20px;

  background: ${(props) =>
    props.disabled ? 'rgba(255, 255, 255, 0.12)' : '#00ffc2'};
  border: ${(props) =>
    props.disabled ? '3px solid transparent' : ' 3px solid #00ffc2'};
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.disabled
      ? '  0px 9px 14px 1px rgba(42, 41, 46, 0.16)'
      : '0px 3px 14px #00ffc2'};
  border-radius: 18px;

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`
