import { Link } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { H1, H2, Spinner } from '../../../components'
import { Nft, NftType } from '../../../store/web3/collection.state'
import { Unbind } from '../Unbind'
import { bn, fEth } from '../Unbind/utils'

interface Props {
  nft: Nft
}

export const DetailedNFTDisplay: React.FC<Props> = ({ nft }) => {
  const { description, max_quantity, name, localVideo } = nft
  const { t } = useTranslation()
  return (
    <NftCard>
      <AbsoluteLink to={'/my-collection'}>
        <StyledSVG
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M16.5007 33C25.5987 33 33.0004 25.598 33.0004 16.4999C33.0004 7.40196 25.5987 0 16.5007 0C7.40279 0 0.000976562 7.40196 0.000976562 16.4999C0.000976562 25.598 7.40279 33 16.5007 33ZM16.5007 2.25872C24.3533 2.25872 30.7417 8.64727 30.7418 16.4999C30.7418 24.3526 24.3534 30.7411 16.5007 30.7413C8.64825 30.7411 2.25984 24.3526 2.25984 16.4998C2.25984 8.64742 8.64825 2.25872 16.5007 2.25872Z"
              fill="white"
            />
            <path
              d="M14.2848 23.567C14.7258 24.0079 15.4409 24.0078 15.8818 23.567C16.323 23.1258 16.323 22.4108 15.8817 21.9696L11.5419 17.63L24.109 17.6288C24.7327 17.6287 25.2382 17.1232 25.2382 16.4992C25.238 15.8755 24.7325 15.3701 24.1088 15.3701L11.5413 15.3713L15.8821 11.0308C16.3232 10.5898 16.3232 9.87451 15.8821 9.43361C15.6615 9.21316 15.3726 9.10278 15.0834 9.10278C14.7945 9.10278 14.5055 9.21316 14.2849 9.43346L8.01637 15.7019C7.80451 15.9136 7.68555 16.2007 7.68555 16.5004C7.6857 16.8 7.80466 17.087 8.01652 17.2992L14.2848 23.567Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="33" height="33" fill="white" />
            </clipPath>
          </defs>
        </StyledSVG>
        {t('backCollection')}
      </AbsoluteLink>
      <Group>
        <Width>
          <MaxContentGroup>
            <InfoCard>
              <StyledLabel>{t('rarityTier')}</StyledLabel>
              <LegendaryCard
                color={
                  nft.rarityType === 'Legendary'
                    ? '#FF9900'
                    : nft.rarityType === 'Ethereal'
                    ? '#C173FF'
                    : nft.rarityType === 'Mythic'
                    ? '#00FF38'
                    : nft.rarityType === 'Rare'
                    ? '#00D1FF'
                    : null
                }
              >
                <TieredH2
                  color={
                    nft.rarityType === 'Legendary'
                      ? '#FF9900'
                      : nft.rarityType === 'Ethereal'
                      ? '#C173FF'
                      : nft.rarityType === 'Mythic'
                      ? '#00FF38'
                      : nft.rarityType === 'Rare'
                      ? '#00D1FF'
                      : null
                  }
                >
                  {nft.rarityType}
                </TieredH2>
              </LegendaryCard>
            </InfoCard>

            <InfoCard>
              <StyledLabel>{t('Redeemable Value per Card')}</StyledLabel>
              <InfoValueCard>
                <NftValue>
                  {nft.type === NftType.STATIC ? (
                    <Spinner size={1.2} />
                  ) : (
                    fEth(bn(nft.rarity.ethBalance).div(nft.rarity.nftAmount), 6)
                  )}{' '}
                  ETH
                </NftValue>
              </InfoValueCard>
            </InfoCard>

            <InfoCard>
              <StyledLabel>{t('Total Unbound Copies Left')}</StyledLabel>
              <InfoMaxCard>
                {nft.type === NftType.STATIC ? (
                  <Spinner size={1.2} />
                ) : (
                  nft.supply
                )}
              </InfoMaxCard>
            </InfoCard>

            <InfoCard>
              <StyledLabel> {t('Max NFT Copies to Ever Exist')}</StyledLabel>
              <InfoMaxCard>{max_quantity}</InfoMaxCard>
            </InfoCard>
            <InfoCard>
              <StyledLabel>{t('Total Copies Unbound')}</StyledLabel>
              <InfoBurnCard>
                {nft.type === NftType.STATIC ? (
                  <Spinner size={1.2} />
                ) : (
                  nft.amountBurned
                )}
              </InfoBurnCard>
            </InfoCard>

            <InfoCard>
              <StyledLabel>Artist</StyledLabel>
              {nft.attributes.find((a) => a.trait_type === 'Artist')?.value}
            </InfoCard>
          </MaxContentGroup>
          <CardGroup>
            <StyledVideo
              loop
              autoPlay
              controls
              width={500}
              height={889}
              src={localVideo.publicURL}
            />

            <p>{description}</p>
          </CardGroup>
        </Width>
      </Group>

      <Unbind nft={nft} />
    </NftCard>
  )
}

const Width = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (min-width: 200px) {
    flex-direction: column;
    width: 100%;
  }

  @media (min-width: 750px) {
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

const StyledVideo = styled.video`
  align-self: center;
  justify-self: center;
  @media (min-width: 250px) {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 750px) {
    width: 300px;
    height: auto;
  }

  @media (min-width: 1200px) {
    width: 350px;
  }

  @media (min-width: 1500px) {
    width: 450px;
  }
`

const CardGroup = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media (min-width: 750px) {
    width: 60%;
  }

  @media (min-width: 1200px) {
    width: 450px;
  }

  > h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  > h1 {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  > p {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-bottom: 1rem;

    @media (min-width: 200px) {
      width: 90vw;
    }
    @media (min-width: 750px) {
      width: inherit;
    }
    @media (min-width: 1200px) {
      width: inherit;
    }
  }
`

const MaxContentGroup = styled.div`
  position: relative;
  align-self: center;
  justify-self: center;
  width: max-content;
  text-align: center;
`

const Group = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: 750px) {
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

const StyledLabel = styled.h4`
  color: white;
  font-size: 1rem;
  opacity: 0.7;
  margin-bottom: 0.5rem;
  font-weight: 300;
`

interface TieredH2 {
  color: any
}

const TieredH2 = styled(H2)<TieredH2>`
  color: ${(props) => (props.color ? props.color : 'white')};
  margin: 0;
  font-size: 1.275rem;
`

const LegendaryCard = styled.div<TieredH2>`
  border: 2px solid ${(props) => (props.color ? props.color : 'white')};
  padding: 1rem;
  display: flex;
  background: black;
  width: 100%;
  border-radius: 18px;
  text-align: center;
`

const InfoValueCard = styled.div`
  border: 2px solid #00ffc2;
  padding: 1rem;
  display: flex;
  background: black;
  width: 100%;
  border-radius: 18px;
  text-align: center;
`
const InfoMaxCard = styled.div`
  border: 2px solid #00d1ff;
  padding: 1rem;
  display: flex;
  background: black;
  border-radius: 18px;
  text-align: center;
`

const InfoBurnCard = styled.div`
  border: 2px solid #b82c00;
  padding: 1rem;
  display: flex;
  background: black;
  border-radius: 18px;
  text-align: center;
`

const InfoCard = styled.div`
  border-radius: 18px;
  padding: 1rem;
  font-size: 1.275rem;
  width: 15rem;
  text-align: center;
`

const StyledSVG = styled.svg`
  margin-right: 0.5rem;
`

const AbsoluteLink = styled(Link)`
  position: absolute;
  top: 1rem;
  left: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`

const NftValue = styled.div`
  margin-left: 0.5rem;
`

const NftInfo = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
`

const NftCard = styled.div`
  position: relative;
  color: white;
  display: grid;

  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  padding-top: 3rem;

  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;

  @media (min-width: 250px) {
  }

  @media (min-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
  }

  @media (min-width: 1600px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`
