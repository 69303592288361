import { BigNumber } from 'ethers'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { H3 } from '../../../components'
import { Nft, NftType } from '../../../store/web3/collection.state'
import {
  selectGasSelf,
  selectUnbindStats,
  selectUnbindTxSelf,
} from '../../../store/web3/unbind.side-effects'
import { CostBreakdown, CostBreakdownProps } from './CostBreakdown'
import { NftAmountInput } from './NftAmountInput'
import {
  Props as UnbindMultiButtonProps,
  UnbindMultiButton,
} from './UnbindMultiButton'
import { bn } from './utils'

interface Props {
  nft: Nft
}
export const Unbind: React.FC<Props> = ({ nft }) => {
  const { t } = useTranslation()
  const [nftsToUnbind, setNftsToUnbind] = useState(0)
  const { estimatedGas, gasPrice } = useSelector(selectGasSelf)
  const unbindState = useSelector(selectUnbindStats)
  const gasCost = BigNumber.from(estimatedGas).mul(gasPrice)
  const txState = useSelector(selectUnbindTxSelf)
  const unbindValuePerNft =
    nft.type === NftType.STATIC
      ? '0'
      : bn(nft.rarity.ethBalance).div(nft.rarity.nftAmount).toString()
  const azukiBurnAmountPerNft =
    nft.type === NftType.STATIC ? '0' : nft.rarity.azukiAmountRequiredToMelt
  const costBreakdownProps: CostBreakdownProps = {
    azukiBurnAmountPerNft,
    unbindValuePerNft,
    ethUsdPrice: unbindState.ethUsdPrice,
    maticUsdPrice: unbindState.maticUsdPrice,
    azukiUsdPrice: unbindState.azukiUsdPrice,
    gasCost,
    nftsToUnbind,
  }

  const unbindMultiButtonProps: UnbindMultiButtonProps = {
    azukiBurnAmountPerNft,
    nftId: nft.tokenId,
    nftsToUnbind,
    txLoadingState: txState.loading,
    l2NftsOwned: nft.type !== NftType.OWNED ? 0 : nft.balanceByLayer.L2,
  }

  return (
    <UnbindWrapper>
      <InputContainer>
        <H3>{t('UNBIND')}</H3>

        <NftAmountInput
          setCountToUnbind={setNftsToUnbind}
          nftsOwned={
            nft.type === NftType.OWNED ? nft.balanceByLayer : undefined
          }
        />
        <CostBreakdown {...costBreakdownProps} />

        <UnbindMultiButton {...unbindMultiButtonProps} />
      </InputContainer>
    </UnbindWrapper>
  )
}

const UnbindWrapper = styled.div`
  margin-bottom: 2rem;
  max-width: 500px;
  > h3 {
    margin-top: 0;
  }
  @media (min-width: 200px) {
    padding: 0rem;
  }

  @media (min-width: 750px) {
    padding: 1rem;
  }

  @media (min-width: 800px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    padding: 0rem;
  }
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max-content;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 1rem;
  align-items: center;
  justify-items: center;
  width: 100%;

  @media (min-width: 200px) {
    width: 100%;
  }

  @media (min-width: 1200px) {
    width: max-content;
  }
`
