import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { useSelector } from 'react-redux'
import { RawStaticNft } from '../../api/graph'
import { DetailedNFTDisplay } from '../../features/my-collection/CollectionDisplay/DetailedNFTDisplay'
import { Layout } from '../../features/my-collection/Layout'
import { RootState } from '../../store'
import { selectCB } from '../../store/web3/collection.state'

type Props = PageProps<{ binderNft: RawStaticNft }, object, object>

const SingleBinderDisplay: React.FC<Props> = ({ data }) => {
  const nft = useSelector<RootState, ReturnType<typeof selectCB>>((state) =>
    selectCB(state, data.binderNft),
  )
  return <Layout renderWith={() => <DetailedNFTDisplay nft={nft} />} />
}

export default SingleBinderDisplay

export const query = graphql`
  query($id: String) {
    binderNft(id: { eq: $id }) {
      tokenId
      localVideo {
        publicURL
      }
      isSecret
      rarityType
      description
      external_url
      name
      max_quantity
      attributes {
        trait_type
        value
      }
    }
  }
`
