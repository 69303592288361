import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import {
  Loading,
  selectOwnershipLoading,
} from '../../../../store/web3/collection.state'
import {
  selectL2AzukiBalance,
  selectL2NativeTokenBalance,
  selectSignerChain,
  selectWeb3ApprovalState,
  selectWeb3Loading,
  selectWeb3SignerType,
  SignerLoading,
  SignerType,
  Web3ApprovalState,
} from '../../../../store/web3/signer'
import {
  selectUnbindIsStarted,
  selectUnbindTxSelf,
  TransactionLoading,
} from '../../../../store/web3/unbind.side-effects'
import { web3Service } from '../../../../store/web3/web3.service'
import { bn } from '../utils'

export enum UnbindState {
  SHOW_DISABLED,
  SHOW_BINDING_FAILED,
  SHOW_PENDING_CONFIRMATION,
  SHOW_CONNECTING_WALLET_PENDING,
  SHOW_CONNECT_WALLET,
  SHOW_WRONG_CHAIN,
  SHOW_UNBIND,
  SHOW_INSUFFICIENT_AZUKI_FUNDS,
  SHOW_INSUFFICIENT_MOMIJI_FUNDS,
  SHOW_APPROVE_AZUKI,
  SHOW_APPROVE_MOMIJI,
  SHOW_LOADING,
  SHOW_TOO_MANY_NFTS,
}

interface UseUnbindStateParams {
  azukiBurnAmountPerNft: string
  nftsToUnbind: number
  l2NftsOwned: number
}

export const useUnbindState = ({
  azukiBurnAmountPerNft,
  nftsToUnbind,
  l2NftsOwned,
}: UseUnbindStateParams) => {
  const isStarted = useSelector(selectUnbindIsStarted)
  const [, l2MaticBalance] = useSelector(selectL2NativeTokenBalance)
  const azukiBalance = useSelector(selectL2AzukiBalance)
  const signerType = useSelector(selectWeb3SignerType)
  const chain = useSelector(selectSignerChain)
  const web3Loading = useSelector(selectWeb3Loading)
  const unbindLoading = useSelector(selectUnbindTxSelf)
  const azukiToBurn = bn(azukiBurnAmountPerNft).mul(nftsToUnbind)
  const approvalStatus = useSelector<
    RootState,
    ReturnType<typeof selectWeb3ApprovalState>
  >((state) => selectWeb3ApprovalState(state, azukiToBurn.toString()))
  const ownerStatsLoadingState = useSelector(selectOwnershipLoading)

  if (unbindLoading.loading === TransactionLoading.PENDING) {
    return UnbindState.SHOW_PENDING_CONFIRMATION
  }

  if (
    web3Loading === SignerLoading.SELECTED ||
    ownerStatsLoadingState === Loading.PENDING
  ) {
    return UnbindState.SHOW_CONNECTING_WALLET_PENDING
  }
  // Let user connect to their wallet if they havent yet
  if (signerType === SignerType.NONE) {
    return UnbindState.SHOW_CONNECT_WALLET
  }

  if (!isStarted) {
    return UnbindState.SHOW_DISABLED
  }

  // If they're on the wrong chain, let them swap to the correct chain
  const expectedChain = web3Service.getRequiredSignerChain()
  if (chain?.chainId !== expectedChain.chainId) {
    return UnbindState.SHOW_WRONG_CHAIN
  }
  if (azukiBurnAmountPerNft === '0') {
    return UnbindState.SHOW_BINDING_FAILED
  }

  if (nftsToUnbind === 0 && l2NftsOwned === 0) {
    return UnbindState.SHOW_INSUFFICIENT_MOMIJI_FUNDS
  }

  // If they dont have sufficient balance, let them know
  if (!l2MaticBalance) {
    console.warn('[UnbindButton] No matic balance was found for account')
    return UnbindState.SHOW_CONNECTING_WALLET_PENDING
  }

  if (bn(azukiBalance).lt(azukiToBurn)) {
    return UnbindState.SHOW_INSUFFICIENT_AZUKI_FUNDS
  }

  if (l2NftsOwned < nftsToUnbind) {
    return UnbindState.SHOW_INSUFFICIENT_MOMIJI_FUNDS
  }

  switch (approvalStatus) {
    case Web3ApprovalState.APPROVE_AZUKI: {
      return UnbindState.SHOW_APPROVE_AZUKI
    }
    case Web3ApprovalState.APPROVE_MOMIJI: {
      return UnbindState.SHOW_APPROVE_MOMIJI
    }
    case Web3ApprovalState.PENDING: {
      return UnbindState.SHOW_LOADING
    }
    case Web3ApprovalState.IDLE: {
      console.error('[SaleEnabledUnbindButton][Web3ApprovalState] IDLE')
      return UnbindState.SHOW_LOADING
    }
    case Web3ApprovalState.SUCCESSFUL: {
      if (nftsToUnbind > 10) {
        return UnbindState.SHOW_TOO_MANY_NFTS
      }

      return UnbindState.SHOW_UNBIND
    }
  }
}
