import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAppDispatch } from '../../../../store'
import {
  TransactionLoading,
  TransactionType,
  unbindGasSlice,
  unbindTxSlice,
} from '../../../../store/web3/unbind.side-effects'
import { HelperSection } from './HelperSection'
import { StyledUnbindButton } from './StyledUnbindButton'

export interface UnbindButtonProps {
  nftId: string
  nftsToUnbind: number
  l2NftsOwned: number
  txLoadingState: TransactionLoading
}

export const UnbindButton: React.FC<UnbindButtonProps> = ({
  nftId,
  nftsToUnbind,
  txLoadingState,
  l2NftsOwned,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const disabled =
    nftsToUnbind <= 0 ||
    l2NftsOwned < nftsToUnbind ||
    nftsToUnbind > 10 ||
    txLoadingState === TransactionLoading.PENDING ||
    txLoadingState === TransactionLoading.ERROR ||
    txLoadingState === TransactionLoading.BROADCASTED
  useEffect(() => {
    if (disabled) {
      return
    }
    dispatch(
      unbindGasSlice.actions.GAS_ESTIMATE_REQUESTED({
        nftId,
        amount: Number(nftsToUnbind),
        txType: TransactionType.UNBIND,
      }),
    )
  }, [nftsToUnbind, nftId, disabled, dispatch])

  return (
    <HelperSection>
      <StyledUnbindButton
        disabled={disabled}
        onClick={() => {
          dispatch(
            unbindTxSlice.actions.TX_REQUESTED({
              nftId,
              amount: nftsToUnbind,
              txType: TransactionType.UNBIND,
            }),
          )
        }}
      >
        {t('unbind')}
      </StyledUnbindButton>
      {!disabled && (
        <Container>
          <WarningH2 topMargin={1}>{t('unbindIrr1')}</WarningH2>
          <WarningH2 topMargin={0}>{t('unbindIrr2')}</WarningH2>
        </Container>
      )}
    </HelperSection>
  )
}

const Container = styled.div`
  width: 80%;
  text-align: center;
`

const WarningH2 = styled.h2<{ topMargin: number }>`
  margin-top: ${(props) => props.topMargin}em;
  color: #f53d3d;
  text-align: center;

  @media (min-width: 200px) {
    font-size: 1.05rem;
  }

  @media (min-width: 760px) {
    font-size: 1.5rem;
  }
`
