import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OwnedKeyedNft } from '../../../store/web3/collection.state'
import { useNftLoadingState } from '../CollectionDisplay/hooks'

interface Props {
  setCountToUnbind: React.Dispatch<React.SetStateAction<number>>
  nftsOwned?: OwnedKeyedNft['balanceByLayer']
}

export const NftAmountInput: React.FC<Props> = ({
  nftsOwned,
  setCountToUnbind,
}) => {
  const { t } = useTranslation()
  const [rawNftsToUnbind, setRawNftsToUnbind] = useState('0')
  const { ownerStatsLoaded } = useNftLoadingState()
  const [isL2, setIsL2] = useState(true)

  useEffect(() => {
    const parsedNftsToUnbind = Number(rawNftsToUnbind)
    if (!Number.isNaN(parsedNftsToUnbind) && parsedNftsToUnbind >= 0) {
      setCountToUnbind(parsedNftsToUnbind)
    }
  }, [rawNftsToUnbind])

  return (
    <StyledBaseContainer>
      <TopRow>
        <StyledLabel>
          {t('inputNumberOfNftsToUnbind')}
          <StyledInput
            disabled={!ownerStatsLoaded || !isL2 || !nftsOwned?.L2}
            placeholder="0"
            onFocus={(e) => (e.target.placeholder = '')}
            value={rawNftsToUnbind}
            onChange={(e) => {
              setRawNftsToUnbind(e.currentTarget.value)
            }}
          />
        </StyledLabel>
      </TopRow>
      <BottomRow>
        <NftBalanceContainer>
          <StyledNftBalance>
            {t('balance')}{' '}
            {nftsOwned
              ? isL2
                ? nftsOwned.L2
                : nftsOwned.L1
              : ownerStatsLoaded
              ? '0'
              : '-'}
          </StyledNftBalance>
          {nftsOwned &&
            (isL2 ? (
              <StyledNftMax
                onClick={() => {
                  setRawNftsToUnbind(Math.min(nftsOwned.L2, 10).toString())
                }}
              >
                {' '}
                {t('max')}
              </StyledNftMax>
            ) : nftsOwned.L1 ? (
              <StyledNftMigrate
                href="https://dokidoki.com/collection"
                rel="noreferrer"
                target="_blank"
              >
                {' '}
                {t('migrate')}
              </StyledNftMigrate>
            ) : null)}
        </NftBalanceContainer>
        <LayerDisplayContainer>
          <StyledLayerButton
            onClick={() => {
              setIsL2(false)
              setRawNftsToUnbind('0')
            }}
            isActive={!isL2}
            disabled={!ownerStatsLoaded}
          >
            L1
          </StyledLayerButton>
          <StyledLayerButton
            onClick={() => {
              setIsL2(true)
              setRawNftsToUnbind('0')
            }}
            isActive={isL2}
            disabled={!ownerStatsLoaded}
          >
            L2
          </StyledLayerButton>
        </LayerDisplayContainer>
      </BottomRow>
    </StyledBaseContainer>
  )
}

const NftBalanceContainer = styled.div``
const StyledNftMax = styled.button`
  color: #00ffc2;
`

const StyledNftMigrate = styled.a`
  color: #00ffc2;
`

const StyledNftBalance = styled.div``

const TopRow = styled.div`
  display: flex;
`
const BottomRow = styled.div`
  display: flex;
  max-width: 500px;
`

const StyledLayerButton = styled.button<{ isActive: boolean }>`
  ${(props) => (props.isActive && !props.disabled ? '' : 'opacity: 50%;')}
  padding: 0.4em;
`

const LayerDisplayContainer = styled.div`
  flex-grow: 1;
  flex-direction: row-reverse;
  display: flex;
`

const StyledBaseContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  -webkit-appearance: none;
  box-sizing: border-box;
  border-radius: 18px;
  color: white;
  background: black;
  transition: 0.3s;
  box-shadow: inset 0 0 0 none;
  width: 100%;
`

const StyledLabel = styled.label`
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledInput = styled.input.attrs((props) => ({
  type: 'number',
}))`
  box-sizing: border-box;
  border-radius: 18px;
  padding: 1rem;
  /* background: ${(props) => (props.disabled ? '#9B9B9B' : 'white')}; */
  margin-left: 1rem;
  cursor: not-allowed;
  color: rgba(243, 241, 239, 0.7);
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  padding: 1rem;
  width: 50%;
  text-rendering: auto;
  background: black;
  color: #ffffff;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  background-color: transparent;
  cursor: text;
  margin: 0em;
  font-family: 'Open Sans';
  font-size: 22px;
  border-width: 0px;
  border-style: none;
  border-color: none;
  border-image: none;
  padding: 12 20px;
  border: 3px solid ${(props) => (props.disabled ? '#9B9B9B' : 'white')};

  @media (min-width: 200px) {
    font-size: 1rem;
  }

  @media (min-width: 750px) {
    font-size: 1.2572rem;
  }

  &::-webkit-input-placeholder {
    color: white;
    opacity: 0.6;
  }

  &:hover {
    border: 3px solid ${(props) => (props.disabled ? '#9B9B9B' : '#00ffc2')};
  }

  &:focus {
    outline: none;
    border: 3px solid #00ffc2;
  }
`
